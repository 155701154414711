<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
            <v-col cols="12" xs="12" sm="6" md="3">
                <v-autocomplete
                    prepend-inner-icon="mdi-account-box"
                    solo
                    v-model="customer"
                    :items="customers"
                    item-value="cust_id"
                    item-text="cust_name"
                    :search-input.sync="search"
                    cache-items
                    hide-no-data
                    label="Customer"
                    class="ma-0 pa-0"
                    hide-details=true
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="2">
                <v-menu
                    ref="modal"
                    v-model="modal"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        solo
                        v-model="date_from"
                        label="Start Date"
                        persistent-hint
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        class="ma-0 pa-0"
                        hide-details=true
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date_from"
                        no-title
                        @input="modal = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="2" >
                <v-menu
                    ref="modal_to"
                    v-model="modal_to"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        solo
                        v-model="date_to"
                        label="End Date"
                        persistent-hint
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        class="ma-0 pa-0"
                        hide-details=true
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date_to"
                        no-title
                        @input="modal_to = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="2" md="2">
                <v-autocomplete
                    prepend-inner-icon="mdi-layers"
                    clearable
                    solo
                    v-model="status"
                    :items="statuss"
                    item-value="value"
                    item-text="label"
                    label="Status"
                    class="ma-0 pa-0"
                    hide-details=true
                ></v-autocomplete>
            </v-col>
             <v-col cols="12" xs="12" sm="3" md="2">
                <v-btn class="mt-1" 
                color="info" elevation="2" large @click="submit()">Search</v-btn>
            </v-col>
            <!-- <v-col cols="12" xs="12" sm="3" md="2" >
                <v-btn
                    class="mr-2 rounded-l p-4"
                    color="error"
                    elevation="2"
                    large
                    @click="submit()"
                >
                    <v-icon dark>
                        mdi-magnify
                    </v-icon>
                    Search
                </v-btn>
            </v-col> -->
        </v-row>
        <v-row class="mt-8">
            <v-col cols="12">
                <v-card class="elevation-12 rounded-l" style="border-top: 3px solid #1565C0">
                    <v-card-text class="mt-5">
                        <p>
                            <em class="green-text text-darken-4">*green: closed</em>,
                            <em class="red-text text-darken-4"> *red: open</em>,
                            <em class="yellow-text text-darken-4"> *yellow: hasn't been closed/open</em>
                        </p>
                        <v-data-table :headers="headers" :items="orders" class="table" :loading="loading" :item-class="itemRowBackground">
                            <template v-slot:[`item.wgt_ord`]="{ item }">
                                {{ $store.getters.convertToCurrency(item.wgt_ord) }}
                            </template>
                            <template v-slot:[`item.wgt_shipped`]="{ item }">
                                {{ $store.getters.convertToCurrency(item.wgt_shipped) }}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        data() {
            return {
                breadcumbs: [
                    {
                    text: 'Sunrise Steel',
                    disabled: false,
                    href: '/admin/sr',
                    },
                    {
                    text: 'Customer Order',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                    {
                    text: 'Tracking Order',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                ],
                snackbar: {
                    color: null,
                    icon: null,
                    mode: null,
                    position: "top",
                    text: null,
                    timeout: 7500,
                    title: null,
                    visible: false
                },
                loading: false,
                customer: '',
                customers: [],
                search: null,
                date_from: '',
                modal: false,
                date_to: '',
                modal_to: false,
                statuss:[
                    {
                        label: 'All',
                        value: ''
                    },
                    {
                        label: 'Open',
                        value: 'O'
                    },
                    {
                        label: 'Close',
                        value: 'C'
                    },
                ],
                status: '',
                orders: [],
                headers:[
                    { text: 'Date Order', value: 'dt_order' },
                    { text: 'Order ID', value: 'order_id' },
                    { text: 'Customer', value: 'cust_name' },
                    { text: 'Descr', value: 'descr' },
                    { text: 'Status', value: 'stat' },
                    { text: 'Wgt Order', value: 'wgt_ord' },
                    { text: 'Wgt Shipped', value: 'wgt_shipped' }
                ]
            }
        },
        mounted(){
            this.$store.dispatch('setOverlay', false)
        },
        methods:{
            itemRowBackground: function (item) {
                if (item.stat === 'O') {
                    return 'red-text text-darken-4'
                } else if(item.stat === 'C'){
                    return 'green-text text-darken-4'
                } else{
                    return 'yellow-text text-darken-4'
                }
            },
            async querySelections (value) {
                await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/CustOrder?search=${value}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.customers = res.data
                });
            },
            async submit(){
                this.loading = true
                this.$store.dispatch('setOverlay', true)

                var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
                var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

                await axios.get(`${process.env.VUE_APP_URL}/api/sr/tracking_order?cust_id=${this.customer ? this.customer : ''}&status=${this.status ? this.status : ''}&dt_start=${start_date ? start_date : ""}&dt_end=${end_date ? end_date : ""}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.loading = false
                    this.$store.dispatch('setOverlay', false)
                    this.orders = res.data
                })
            },
        },
        watch: {
            search (val) {
                val && val !== this.customer && this.querySelections(val)
            },
        }
    }
    </script>
    